import * as React from "react"
import Container from '../components/Container'
import IndentedContent from '../components/helpers/IndentedContent'
import ContentPage from '../components/layout/ContentPage'
import SectionEntry from '../components/SectionEntry'
import SectionEntryContent from '../components/SectionEntryContent'
import SectionEntryTitle from '../components/SectionEntryTitle'
import { Steps } from 'antd';
import SEO from '../components/seo'

const { Step } = Steps;

// markup
const ImprintPage = () => {
  return (
    <main>
      <SEO title="Allgemeine Geschäftsbedingungen" />

      <Container containerStyles={{ pointerEvents: 'none', position: 'absolute', top: 0, left: 0, right: 0, height: '700px', backgroundImage: 'radial-gradient(at left top, rgba(81, 144, 224, 0.08) 20%, transparent 80%), radial-gradient(at right center, rgba(122, 81, 224, 0.1) 20%, transparent 75%)' }}>
      </Container>


      <ContentPage>
        <Container>
          <IndentedContent>
            <SectionEntry>
              <SectionEntryTitle>Allgemeine Geschäftsbedingungen</SectionEntryTitle>
            </SectionEntry>
          </IndentedContent>
        </Container>

        <Container>
          <IndentedContent>


            <h3>§1 Vertragsgegenstand, Vertragsabschluss und Nutzungsbedingungen</h3>

            <p>(1) Der Betreiber erbringt für seine Kunden Software-as-a-Service-Dienste, die über das Medium Internet
              bereitgestellt werden. Vertragsgegenstand ist die Überlassung von Software des Betreibers zur Nutzung über das
              Internet und die Speicherung von Daten des Kunden auf den Servern des Betreibers.</p>

            <p>(2) Der Betreiber stellt dem Kunden die Software-Lösung petoffice für die Abonnementsdauer in der jeweils aktuellen Version kostenpflichtig zur Nutzung zur Verfügung. Der Leistungsumfang ergibt sich aus der Beschreibung des Betreibers unter https://www.petoffice.eu/preise/.</p>

            <p>(3) Nach der Registrierung auf petoffice.eu erhält der Kunde einen Zugang in Form von einer eigenen Web-Adresse, sowie Zugangsdaten in Form von E-Mail-Adresse und Passwort. Der Kunde ist für deren sichere Aufbewahrung und für die Vermeidung von Zugriffen durch unbefugte Dritte selbst verantwortlich.</p>

            <p>(4) Registrierung unter Angabe falscher persönlicher Daten, Adress- und weiteren Kontaktdaten ist nicht gestattet. Im Falle von offensichtlich falschen Angaben behält sich der Betreiber vor, das Konto zu löschen.</p>

            <p>(5) Die Software-Lösung petoffice kann über einen Zeitraum von 30 Tagen kostenlos getestet werden. Nach der Testphase wird der Kunde zum Abschluss eines monatlich kostenpflichtigen Abonnements aufgefordert. Ist dies nicht gewünscht, so bleibt der Zugang gesperrt.</p>

            <p>(6) Der Kunde verpflichtet sich für die Überlassung des Zugriffs auf die Software-Lösung und für die Speicherung seiner Daten das vereinbarte monatliche Entgelt zu zahlen. Der Betreiber behält sich Gebührenerhöhungen ausdrücklich vor. Als Kleinunternehmer im Sinne von § 19 Abs. 1 Umsatzsteuergesetz (UStG) wird Umsatzsteuer nicht berechnet.</p>

            <p>(7) Der Kunde verpflichtet sich, die Software-Lösung petoffice nicht in einer Art und Weise zu nutzen, welche die Verfügbarkeit, Integrität und Datensicherheit negativ beeinflusst. Der Kunde verpflichtet sich, die Betreiber für etwaige Schäden, inkl. Ansprüchen Dritter sowie Folgekosten jeglicher Art, freizuhalten, sofern er gegen die Allgemeinen Geschäftsbedingungen verstößt.</p>

            <p>(8) Der Vertrag zur Überlassung des Zugriffs auf die Software-Lösung petoffice und die Speicherung der dort hinterlegten Daten wird auf unbestimmte Zeit geschlossen. Die Kündigung ist jederzeit zum Ende des laufenden Monats ohne Frist per E-Mail an info@petoffice.eu unter Angabe des Vereinsnamens möglich. Die Kündigung kann nur durch den Vertragspartner erfolgen.</p>

            <p>(9) Der Betreiber behält sich das Recht vor, bei Zahlungsverzug durch den Kunden den Zugang zur Software-Lösung petoffice vorübergehend zu sperren oder vom Vertrag zurückzutreten.</p>


            <h3>§2 Datenschutz und Datensicherheit</h3>

            <p>(1) Der Betreiber folgt den Bestimmungen der DSGVO. Zur Erfüllung dieser Anforderungen wird eine Vereinbarung über den Datenschutz sowie eine Vereinbarung zur Auftragsdatenverarbeitung abgeschlossen.</p>

            <p>(2) Der Betreiber behält sich vor, den Kunden zu Marketingzwecken als zu Referenz nennen - außer er widerspricht diesem Punkt per E-Mail.</p>

            <p>(3) Der Betreiber verpflichtet sich geeignete Vorkehrungen gegen den Datenverlust und den unbefugten Zugriff Dritter zu treffen. Der Kunde hat kein Recht auf die Wiederherstellung der gespeicherten Daten, die Entscheidung zur Wiederherstellung liegt im Ermessen des Betreibers. Der Kunde verpflichtet sich, seine Daten regelmäßig über die Sicherungsfunktion in der Software-Lösung selbst zu sichern.</p>

            <p>(4) Sämtliche Daten werden während der Übertragung nach aktuellen Industriestandards verschlüsselt, um Vertraulichkeit und Integrität dieser Daten zu gewährleisten.</p>

            <p>(5) Der Kunde ist Alleinberechtigt an seinen Daten und kann auf die Herausgabe dieser Daten jederzeit und ohne Angabe von Gründen bestehen.</p>

            <p>(6) Der Kunde hat keinen Anspruch auf die Herausgabe der zur Verwendung der Daten geeigneten Software.</p>

            <p>(7) Die zur Übergabe der personenbezogenen Daten geltenden Sonderbestimmungen finden sich in der Datenschutzerklärung und im Auftragsverarbeitungsvertrag.</p>


            <h3>§3 Verfügbarkeit und Gewährleistung</h3>

            <p>(1) Der Betreiber leistet für die Betriebsbereitschaft der Software-Lösung Gewähr.</p>

            <p>(2) Der Betreiber überwacht die Funktionsfähigkeit der Software und ergreift geeignete technische Maßnahmen um nutzungseinschränkende und nutzungsvermeidende Fehler zeitnah zu beseitigen.</p>

            <p>(3) Aus Gründen, die außerhalb der Einflussnahme des Betreibers liegen, kann es zu Ausfällen der Software-Lösung kommen. In diesem Fall garantiert der Betreiber, angemessene Maßnahmen zu ergreifen um die Verfügbarkeit der Software-Lösung schnellstmöglich gewährleisten zu können.</p>

            <p>(4) Die sich aus der Produktbeschreibung auf der Homepage ergebenden Systemanforderungen müssen beim Kunden
              erfüllt sein. Dieser trägt hierfür selbst die Verantwortung.</p>


            <h3>§4 Haftung</h3>

            <p>(1) Jegliche Schäden, die aus der Nutzung der Platform entstehen, sind von der Haftung durch den Betreiber ausgeschlossen. Weiterhin haftet der Betreiber nicht für die unbefugte Kenntniserlangung persönlicher Daten durch Dritte. Der Betreiber übernimmt auch keine Haftung, wenn Daten missbräuchlich verwendet werden, die Dritten vom Kunden selbst zugänglich gemacht wurden.</p>

            <p>(2) Die alleinige Verantwortung für die gespeicherten Daten liegt beim Kunden.</p>

            <p>(3) Der Kunde stellt den Betreiber frei von sämtlichen Ansprüchen Dritter, die sich aus den vom Kunden gespeicherten Daten ergeben. Sofern dem Betreiber aufgrund möglicher Rechtsverletzungen Kosten entstehen, verpflichtet sich der Kunde zur Übernahme dieser.</p>

            <p>(4) Der Betreiber behält sich vor Zugänge zu sperren, wenn der begründete Verdacht auf Speicherung rechtswidriger Daten besteht.</p>


            <h3>§5 Support</h3>

            <p>(1) Der Betreiber ermöglicht dem Kunden, Anfragen zur Software-Lösung per E-Mail zu senden und wird diese so schnell wie möglich bearbeiten.</p>


            <h3>§6 Widerrufsrecht</h3>

            <p>(1) Binnen 14 Tagen nach Vertragsbeginn kann der Kunde die Vertragserklärung ohne Angabe von Gründen in Textform (E-Mail an info@petoffice.eu) widerrufen. Zur Wahrung der Widerrufsfrist genügt die rechtzeitige Absendung des Widerrufs.</p>


          </IndentedContent>
        </Container>
      </ContentPage>
    </main>
  )
}

export default ImprintPage
